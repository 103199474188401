declare const process: {
    env: {
        CLIENT_ID: string,
        KEYCLOAK_AUTH: string,
        HOST: string,
        API_HOST: string,
    };
};

export const redirectUrl = `${process.env.HOST}/login.html`;

export const loginUrl = `${process.env.KEYCLOAK_AUTH}?client_id=${process.env.CLIENT_ID}&redirect_uri=${redirectUrl}&response_type=code`;

export const baseUrl = process.env.API_HOST;

export function getToken(): String {
    const token = window.localStorage.getItem("token");

    if (token == null) {
        window.location.replace(loginUrl);
    } else {
        return token;
    }
}